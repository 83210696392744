import { Component, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/auth.service';
import { AonPermission } from 'libraries/AonSDK/src/aon';
import { predefinedRoles } from 'src/app/app.globals';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { MenuItem } from 'src/app/core/models/interface/menu-item';
import { DropdownMenuComponent } from '../../components/dropdown-menu/dropdown-menu.component';
import { AppComponent } from '../../../app.component';
import { DomainService } from 'src/app/core/services/domain.service';

@Component({
  selector    : 'app-topbar',
  templateUrl : './top-bar.component.html',
  styleUrls   : ['./top-bar.component.scss'],
  host        : {
    '[style.width]' : "'100%'",
    '[style.height]': "'100%'",
  },
})

export class TopBarComponent implements OnInit, OnChanges {
  @ViewChild('first') dropdownMenuComponent: DropdownMenuComponent = new DropdownMenuComponent;
  menuItem          : MenuItem [] = []
  displayHomeIcon   : boolean     = false;
  usserLoggged      : boolean     = this.auth.isLoggedIn();
  enterpriseSelected: boolean     = false;
  // Idiomas que tenemos (traducir y agregar)
  languageList: { [key: string]: string } =  {
    'LANGUAGE.ENGLISH'  : 'en',
    'LANGUAGE.SPANISH'  : 'es',
    'LANGUAGE.CATALAN'  : 'ca',
    'LANGUAGE.EUSKERA'  : 'eu',
    'LANGUAGE.GALICIAN' : 'gl',
    'LANGUAGE.FRENCH'   : 'fr',
    'LANGUAGE.CHINESE'  : 'zh',
    'LANGUAGE.GERMAN'   : 'de'
  }
  languages: string[] = ! environment.production ? [
    // PRE
    'LANGUAGE.ENGLISH', 'LANGUAGE.SPANISH',  'LANGUAGE.CATALAN',
    'LANGUAGE.EUSKERA', 'LANGUAGE.GALICIAN', 'LANGUAGE.FRENCH',
    'LANGUAGE.CHINESE', 'LANGUAGE.GERMAN'
  ] : [
    // PRO
    'LANGUAGE.ENGLISH', 'LANGUAGE.SPANISH'
  ]
  // Opciones que tenemos si estas logueado (traducir)
  profileOptions: string[] = [
    'HEADER.EDIT_PROFILE', 'HEADER.HELP', 'HEADER.LANGUAGE', 
    'HEADER.LOGOUT'
  ]

  constructor(
    private router            : Router,
    private auth              : AuthService,
    private enterpriseService : EnterpriseService,
    private translateService  : TranslateService,
    private appComponent      : AppComponent,
    public  domainService     : DomainService
  ){
    // Los datos a traducir, si estas logueado unificamos, si no solo los idiomas
    const optionsTranslate = this.usserLoggged ? this.languages.concat(this.profileOptions) : this.languages;

    // Si estamos logueados
    if(this.usserLoggged){
      // Comprobar si tenemos una empresa seleccionada
      this.enterpriseService.existEntepriseData().then((data) => {
        this.enterpriseSelected = data;
      });
      // Los permisos que tenemos
      this.auth.getUserRol().then((permissions) => {
        // La ruta de editar perfil a la que vamos, dependiendo del tipo (aunque el componente sea el mismo)
        let routerEditProfile: string = '';
        if(permissions.includes(predefinedRoles.ADMIN) || permissions.includes(predefinedRoles.ENTERPRISE)){
          routerEditProfile = 'edit-profile';
        } else if(permissions.includes(predefinedRoles.EMPLOYEE)){
          routerEditProfile = 'edit-profile-employee';
        }
        // Opciopnes del menu de perfil
        this.translateService.get(optionsTranslate).subscribe( result => {
          this.menuItem = [
            {root:true, text: result['HEADER.EDIT_PROFILE'], icon:'person_pin' , colorIcon:'black', routerlink: routerEditProfile},
            {root:true, text: result['HEADER.LANGUAGE']    , icon:'language'   , colorIcon:'black', children  : this.addLanguage(result)},
            {root:true, text: result['HEADER.LOGOUT']      , icon:'exit_to_app', colorIcon:'black', click:() => this.logout()},
          ];
          console.log(this.menuItem)
        });
      });
    } else {
      // Si no estamos logueados
      this.translateService.get(optionsTranslate).subscribe( result => {
        this.menuItem = this.addLanguage(result);
      });
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      const contentContainer        = document.querySelector('.mat-sidenav-content')  || window;
      const contentLayoutContainer  = document.querySelector('#layaut-componet-body') || window;
      contentContainer.scrollTo(0, 0);
      contentLayoutContainer.scrollTo(0, 0);
      event instanceof NavigationEnd ? this.checkCurrentRoute() : null
    });
  }

  checkCurrentRoute() {
    // Si esta en los dos home que tenemos, no muestre el icono de volver a la home en ellos
    this.displayHomeIcon = this.router.url != '/home' && this.router.url != '/main' ? true : false;
  }

  ngOnChanges(): void {
  }

  logout() {
    this.auth.logout();
  }

  selectLanguage(language: string) {
    this.appComponent.selectLanguage(language);
  }

  addLanguage(result: any) {
    const root = this.usserLoggged ? false : true
    let menuItemlanguages: MenuItem [] = [];
    // Agregamos los idiomas que se van a usar
    this.languages.forEach((language) => {
      menuItemlanguages.push(
        {root: root, text: result[language], click:() => this.selectLanguage(this.languageList[language])}
      )
    });
    return menuItemlanguages;
  }
}
