export enum FilterOrder {
    ASC = 'asc',
    DESC = 'desc'
}

export enum InvoiceOrder {
    DATE = 'date',
    REFERENCE = 'reference',
    NAME = 'name',
    TOTAL = 'total'
}

export enum ContactOrder {
    NAME = 'name',
    COUNTRY = 'nationality',
    DOCUMENT = 'document'
}

export enum ProductOrder {
    CODE = 'code',
    NAME = 'name',
    CATEGORY = 'category'
}

export enum InvoiceFields {
    GLOBAL = 'global',
    TYPE = 'invoicetype',
    STATUS = 'invoicestatus',
    CLASS = 'invoiceclass',
    NUMBER = 'number',
    CONTACT = 'contact',
    TRIMESTER = 'trimester',
    YEAR = 'year',
    TOTAL = 'total',
    FROM = 'from',
    TO = 'to',
}

export enum InvoiceSerieFields {
    VALUE = 'value'
}

export enum ContactFields {
    TYPE = 'type',
    NAME = 'value',
    DOCUMENT = 'value',
    GLOBAL = 'global',
    WITH_DOCUMENT = 'with_document',
    STATUS = 'status',
}

export enum ProductFields {
    GLOBAL = 'global',
    CLASS = 'kind',
    STATUS = 'status'
}

export enum MessageFields {
    TYPE = 'type',
    STATUS = 'status',
    REQUEST_TYPE = 'source_id',
    GLOBAL = 'global'
}

export enum TaskHolderFields {
    GLOBAL = 'global'
}

export enum MessageIntervalFields {
    DATE = 'date'
}

export enum MessageChatFields {
    ID_MESSAGE = 'idmessage'
}

export enum TaxModelFields {
    TRIMESTER = 'trimester',
    YEAR = 'year',
    NAME = 'name'
}

export enum FolderFields {
    PARENT = 'parent',
    NAME = 'name',
    GLOBAL = 'global',
    PATH = 'path'
}

export enum DocumentFields {
    PATH = 'path',
    GLOBAL = 'global',
    EMPLOYEE_DOCUMENT = 'document',
}

export enum ContractFields {
    STATUS = 'status',
    END_DATE = 'enddate',
    START_DATE = 'startdate',
    NAME = 'name',
    WORKPLACE = 'workplace',
    CCC = 'code',
    CONTRACT_ID = 'contract',
    PATH = 'path',
    GLOBAL = "global"
}

export enum MarkFields {
    END_DATE = 'enddate',
    START_DATE = 'startdate',
    GLOBAL = 'global'
}

export enum BankFields {
    ACTIVE = 'active'
}

export enum SalaryFields {
    GLOBAL = 'global',
    DOCUMENT = 'document',
    DATE_FROM = 'from',
    DATE_TO = 'to',
}

export enum BankStatementsFields {
    AMOUNT = 'amount',
    STATUS = 'status',
    DESCRIPTION = 'description',
    DATE_FROM = 'from',
    DATE_TO = 'to',
    ID_BANK = 'id'
}
export enum CccFields {
    GEOZONE = 'geozone'
}