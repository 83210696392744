import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { Bank, BankJSON } from "../models/Bank";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { NORDIGEN_BANK_URL, BANK_URL } from "../utils/ApiUrls";
import { IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IBank, IBankBalances, IBankMovements } from "../aon";
import { BankFilter, BankStatementsFilter } from "../utils/ModelsFilters";
import { AonFilter } from "../utils/AonFilter";
import { BankFields, BankStatementsFields } from "../utils/ModelsFields";
import { BankMovementsJSON } from "../models/BankMovements";
import { BankBalancesJSON } from "../models/BankBalances";

export class BankRepository extends GenericRepository<Bank> implements IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository<Bank>, ISingleObjectCrudRepository<Bank> {

    async getCollection(filter?: BankFilter): Promise<ICollection<Bank>> {
        let response = await ApiHttpRequest.get(BASE_URL + BANK_URL.GET_BANK_LIST, {}, {});
        let collection: ICollection<Bank> = new Collection<Bank>();
        if(response.length == 0){
            return collection;
        }else{
        response.forEach((element: any) => {
            collection.add(BankJSON.parseDataToReceive(element))
        })
        let active = new AonFilter<BankFields, never, never>();
        active.addField(BankFields.ACTIVE, true);
        return collection.filter(active.getFilter());
        }
    }
    async getOneBank(key ?: string): Promise<IBank> {
        let response = await ApiHttpRequest.get(BASE_URL + BANK_URL.GET_BANK + '?id=' + key, {}, {});
        return BankJSON.parseDataToReceive(response);
    }
    async create(element: Bank): Promise<Bank> {
        let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.CREATE, {}, BankJSON.parseDataToSend(element));   
        if(!(response?.type! == 'error'))
            return response
                else
            throw new ErrorResponse('0701');
    }
    async update(element: Bank): Promise<Bank> {
       let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.UPDATE, {}, BankJSON.parseDataToSend(element));
        if(!(response?.type! == 'error'))
            return response;
        else
            throw new ErrorResponse('0702');
    }

    async delete(key: string): Promise<void> {
        let json = {
            id : key
        }
        let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.DELETE , {}, json);
        return response
    }
     async getBalances(): Promise<IBankBalances> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_BALANCES, {}, {});
        return BankBalancesJSON.parseDataToReceive(response) ;
    }
     async getMovements(filter? : BankStatementsFilter): Promise<IBankMovements> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS, {}, {});
        return BankMovementsJSON.parseDataToReceive(response);
    }
    async getMovementsDB(filter ?: BankStatementsFilter): Promise<IBankMovements> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS_DB, generateFilter(filter)),{} ,{});
        return BankMovementsJSON.parseDataToReceive(response) ;
    } 
     async getNordigenLink(key ?: string): Promise<void> {
     let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LINK + '?id=' + key, {}, {});
     const link = response?.link;
     if(link !== 'Already linked'){
        window.open(link, '_blank');
     }else{
        throw new ErrorResponse('0708');
     }
    }
    async movementsToDB(key: string): Promise<boolean> {
        let response = await ApiHttpRequest.post(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS +'?id='+key , {}, {});
        return response;
    }
    async balancesToDB(key: string): Promise<boolean> {
        let response = await ApiHttpRequest.post(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_BALANCES +'?id='+key , {}, {});
        return response;
    }
    async compareBalanceDate(): Promise<boolean>{
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.COMPARE_BALANCE_DATE, {}, {});
        return response
    }
}

let generateFilter = (filter?: BankStatementsFilter) => {
    let page = filter?.pageNum ? filter?.pageNum : 1;
    let perPage = filter?.pageItems ? filter?.pageItems : 20;
    let id = filter?.fields?.get(BankStatementsFields.ID_BANK) ? filter?.fields?.get(BankStatementsFields.ID_BANK) : '';
    let status = filter?.fields?.get(BankStatementsFields.STATUS) ? filter?.fields?.get(BankStatementsFields.STATUS) : undefined;
    let from = filter?.fields?.get(BankStatementsFields.DATE_FROM) ? filter?.fields?.get(BankStatementsFields.DATE_FROM) : '';
    let to = filter?.fields?.get(BankStatementsFields.DATE_TO) ? filter?.fields?.get(BankStatementsFields.DATE_TO) : '';
    let description = filter?.fields?.get(BankStatementsFields.DESCRIPTION) ? filter?.fields?.get(BankStatementsFields.DESCRIPTION) : '';
    let amount = filter?.fields?.get(BankStatementsFields.AMOUNT) ? filter?.fields?.get(BankStatementsFields.AMOUNT) : '';
    let json = {
        page: page,
        perPage: perPage,
    };

    if(id != ''){
        Object.defineProperty(json, 'id', {
            value: id,
            enumerable: true
        });
    }
    if(status != undefined){
        Object.defineProperty(json, 'status', {
            value: status,
            enumerable: true
        });
    }
    if(from != '')
        Object.defineProperty(json, 'from', {
            value: new Date(from).toISOString(),
            enumerable: true
        });
    if(to != '')
        Object.defineProperty(json, 'to', {
            value: new Date(to).toISOString(),
            enumerable: true
        });
    if(description != '')
        Object.defineProperty(json, 'description', {
            value: description,
            enumerable: true
        });
    if(amount != '')
        Object.defineProperty(json, 'amount', {
            value: amount,
            enumerable: true
        });
    return json;
}